
export const getPlatformType = () => {
  const platform = localStorage.getItem('platform')
  if (platform) return platform
  else return null
}

//res 文件流  fileName 文件名称 type 文件类型
export const downloadFile = (data, title, type) => {
  var elink = document.createElement("a")
  elink.download = title
  elink.style.display = "none"
  let typelist = {
    txt: "text/plain",
    png: "image/png",
    jpg: "image/jpeg",
    jpeg: "image/jpeg",
    htm: "text/html",
    html: "text/html",
    csv: "text/csv",
    zip: "application/zip",
    rar: "application/x-rar-compressed",
    tar: "application/x-tar",
    sh: "application/x-sh",
    avi: "video/x-msvideo",
    mp4: "video/mp4",
    // 'doc': 'application/msword',
    doc: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    json: "application/json",
    xls: "application/vnd.ms-excel",
    xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    pdf: "application/pdf",
    "7z": "application/x-7z-compressed",
    gz: "application/x-gzip",
    sql: "application/x-sql",
  }
  var blob = new Blob([data], { type: typelist[type] + ";charset=utf-8" })
  elink.href = window.URL.createObjectURL(blob)
  document.body.appendChild(elink)
  elink.click()
  document.body.removeChild(elink);
}
