<template>
  <div>
    <div style="margin-bottom: 10px;display:flex; align-items: center;">
      <el-button type="text" @click="$router.go(-1)" style="margin-right: 10px">返回</el-button>
      <div>{{title}}</div>
    </div>
    <div v-if="auditObj" style="margin-bottom: 10px">
      <template v-if="typeIdVal == 'bz_id'">
        <el-table :data="[auditObj]" :show-header="false" border>
          <el-table-column label="报装人" prop="linkMan"></el-table-column>
          <el-table-column label="联系电话" prop="phone"></el-table-column>
          <el-table-column label="报装地址" prop="address"></el-table-column>
          <el-table-column label="报装描述" prop="remark"></el-table-column>
          <el-table-column label="合同地址" prop="contractpath">
            <template slot-scope="scope">
              <a :href="scope.row.contractpath" target="_blank">{{
                scope.row.contractpath
              }}</a>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-link
                :underline="false"
                type="primary"
                @click="showContract(scope.row)"
                >合同地址</el-link
              >
              <el-link
                :underline="false"
                type="primary"
                style="margin: 0 10px"
                @click="showModalInfo(scope.row)"
                >添加审核</el-link
              >
              <el-link
                :underline="false"
                type="primary"
                @click="showContractInfo(scope.row)"
                >生成合同</el-link
              >
            </template>
          </el-table-column>
        </el-table>
      </template>
      <template v-if="typeIdVal == 'bx_id'">
        <el-table :data="[auditObj]" :show-header="false" border>
          <el-table-column label="报修人" prop="username"></el-table-column>
          <el-table-column label="联系电话" prop="phone"></el-table-column>
          <el-table-column label="报修地址" prop="address"></el-table-column>
          <el-table-column label="报修描述" prop="note"></el-table-column>
          <el-table-column label="合同地址" prop="contractpath">
            <template slot-scope="scope">
              <a :href="scope.row.contractpath" target="_blank">{{
                scope.row.contractpath
              }}</a>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-link
                :underline="false"
                type="primary"
                style="margin-right: 10px"
                @click="showContract(scope.row)"
                >合同地址</el-link
              >
              <el-link
                :underline="false"
                type="primary"
                style="margin: 0 10px"
                @click="showModalInfo(scope.row)"
                >添加审核</el-link
              >
              <el-link
                :underline="false"
                type="primary"
                @click="showContractInfo(scope.row)"
                >生成合同</el-link
              >
            </template>
          </el-table-column>
        </el-table>
      </template>
      <template v-if="typeIdVal == 'gxh_id'">
        <template v-if="title == '过户详情'">
          <el-table :data="[auditObj]" :show-header="false" border>
            <el-table-column label="用户姓名" prop="linkman"></el-table-column>
            <el-table-column label="联系电话" prop="phone"></el-table-column>
            <el-table-column label="地址" prop="address"></el-table-column>
            <el-table-column label="燃气户号" prop="conNo"></el-table-column>
            <el-table-column
              label="新用户姓名"
              prop="newlinkman"
            ></el-table-column>
            <el-table-column label="联系电话" prop="newphone"></el-table-column>
            <el-table-column label="合同地址" prop="contractpath">
              <template slot-scope="scope">
                <a :href="scope.row.contractpath" target="_blank">{{
                  scope.row.contractpath
                }}</a>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <el-link
                  :underline="false"
                  type="primary"
                  style="margin-right: 10px"
                  @click="showContract(scope.row)"
                  >合同地址</el-link
                >
                <el-link
                  :underline="false"
                  type="primary"
                  style="margin: 0 10px"
                  @click="showModalInfo(scope.row)"
                  >添加审核</el-link
                >
                <el-link
                  :underline="false"
                  type="primary"
                  @click="showContractInfo(scope.row)"
                  >生成合同</el-link
                >
              </template>
            </el-table-column>
          </el-table>
        </template>
        <template v-else>
          <el-table :data="[auditObj]" :show-header="false" border>
            <el-table-column label="用户姓名" prop="linkman"></el-table-column>
            <el-table-column label="联系电话" prop="phone"></el-table-column>
            <el-table-column label="地址" prop="address"></el-table-column>
            <el-table-column label="燃气户号" prop="conNo"></el-table-column>
            <el-table-column label="合同地址" prop="contractpath">
              <template slot-scope="scope">
                <a :href="scope.row.contractpath" target="_blank">{{
                  scope.row.contractpath
                }}</a>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <el-link
                  :underline="false"
                  type="primary"
                  style="margin-right: 10px"
                  @click="showContract(scope.row)"
                  >合同地址</el-link
                >
                <el-link
                  :underline="false"
                  type="primary"
                  style="margin: 0 10px"
                  @click="showModalInfo(scope.row)"
                  >添加审核</el-link
                >
                <el-link
                  :underline="false"
                  type="primary"
                  @click="showContractInfo(scope.row)"
                  >生成合同</el-link
                >
              </template>
            </el-table-column>
          </el-table>
        </template>
      </template>
    </div>
    <el-table :data="detailList">
      <el-table-column label="审核人" prop="audit_user"></el-table-column>
      <el-table-column label="审核时间" prop="updatetime"></el-table-column>
      <el-table-column label="审核状态" prop="audit_status"></el-table-column>
      <el-table-column label="审核备注" prop="audit_content"></el-table-column>
      <el-table-column label="附件" prop="file_path">
        <template slot-scope="scope">
          <el-link title="查看文件" @click="showFile(scope.row)">{{
            scope.row.file_path
          }}</el-link>
        </template>
      </el-table-column>
      <el-table-column label="附件上传时间" prop="file_time"></el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-link :underline="false" type="danger" @click="delAuditInfo(scope.row)">删除</el-link>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog title="添加审核信息" :visible.sync="isshowAdd" append-to-body>
      <el-form label-width="120px" :model="auditForm" :rules="auditRules" ref="auditFormRef">
        <el-form-item label="审核状态：" prop="audit_status">
          <el-select
            v-model="auditForm.audit_status"
            placeholder="请选择审核状态"
          >
            <el-option
              v-for="val in [
                '审核通过',
                '业务受理',
                '确定方案',
                '工程施工',
                '验收通气',
                '已完成',
              ]"
              :key="val"
              :label="val"
              :value="val"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="审核时间：" prop="updatetime">
          <el-date-picker
            v-model="auditForm.updatetime"
            type="datetime" value-format="yyyy-MM-dd HH:mm:ss"
            format="yyyy-MM-dd HH:mm:ss"
            placeholder="选择审核时间"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="审核备注：">
          <el-input
            type="textarea"
            v-model="auditForm.audit_content"
            placeholder="请输入审核备注"
          ></el-input>
        </el-form-item>
        <el-form-item label="上传附件：">
          <el-upload action="" :auto-upload="false" ref="upload" :before-upload="checkUploadFile" :http-request="uploadFileAndInfo" :on-remove="handleRemove" :on-change="handleChange">
            <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
            <div slot="tip" class="el-upload__tip">单文件上传,文件大小限制50mb,文件格式支持pdf/word/excel等常用文件格式</div>
          </el-upload>
        </el-form-item>
        <el-form-item label="附件上传时间：">
          <el-date-picker
            v-model="auditForm.file_time"
            type="datetime" value-format="yyyy-MM-dd HH:mm:ss"
            format="yyyy-MM-dd HH:mm:ss"
            placeholder="选择附件上传时间"
          >
          </el-date-picker>
        </el-form-item>
        <div style="text-align: center">
          <el-button @click="isshowAdd = false">取消</el-button>
          <el-button type="primary" @click="saveAudit()">确定</el-button>
        </div>
      </el-form>
    </el-dialog>
    <el-dialog title="添加合同" :visible.sync="isshowEdit" append-to-body>
      <el-form label-width="120px">
        <el-form-item label="合同地址：">
          <el-input
            v-model="contractForm.contractpath"
            placeholder="请输入合同地址"
          ></el-input>
        </el-form-item>
        <div style="text-align: center">
          <el-button @click="isshowEdit = false">取消</el-button>
          <el-button type="primary" @click="save()">确定</el-button>
        </div>
      </el-form>
    </el-dialog>
    <el-dialog title="生成合同" :visible.sync="isShowContract" append-to-body>
      <el-form label-width="120px">
        <el-form-item label="是否居民：">
          <el-radio-group v-model="contractObj.type">
            <el-radio label="1">居民</el-radio>
            <el-radio label="2">非居民</el-radio>
          </el-radio-group>
        </el-form-item>
        <template v-if="contractObj.type == 2">
          <el-form-item label="企业名称：">
            <el-input
              v-model="contractObj.organizeName"
              placeholder="请输入企业名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="营业执照：">
            <el-input
              v-model="contractObj.licenseNumber"
              placeholder="请输入企业营业执照"
            ></el-input>
          </el-form-item>
        </template>
        <el-form-item label="用户姓名：">
          <el-input
            v-model="contractObj.name"
            placeholder="请输入用户姓名"
          ></el-input>
        </el-form-item>
        <el-form-item label="身份证号：">
          <el-input
            v-model="contractObj.idCard"
            placeholder="请输入身份证号"
          ></el-input>
        </el-form-item>
        <el-form-item label="报装地址：">
          <el-input
            v-model="contractObj.address"
            placeholder="请输入报装地址"
          ></el-input>
        </el-form-item>
        <el-form-item label="联系电话：">
          <el-input
            v-model="contractObj.mobile"
            placeholder="请输入报装地址"
          ></el-input>
        </el-form-item>

        <div style="text-align: center">
          <el-button @click="isShowContract = false">取消</el-button>
          <el-button
            type="primary"
            @click="saveContract()"
            v-loading="isLoading"
            >生成合同</el-button
          >
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  getAuditInfoById,
  addAuditInfoById,
  deleteAuditInfo,
  addContractPathById,
  contractCreate,
  readFile
} from "@/api/audit.js";
import moment from "moment";
import { downloadFile} from "@/utils/index.js";
export default {
  data() {
    return {
      auditObj: null,
      auditForm: {
        audit_status: "",
        audit_content: "",
        audit_user: "",
        updatetime: moment().format("YYYY-MM-DD HH:mm:ss"),
        file_time: moment().format("YYYY-MM-DD HH:mm:ss"),
      },
      auditRules: {
        audit_status: [
          { required: true, message: "请选择审核状态", trigger: "blur" },
        ],
        updatetime: [
          { required: true, message: "请选择审核时间", trigger: "blur" },
        ],
        // audit_content: [
        //   { required: true, message: "请输入审核备注", trigger: "blur" },
        // ],
      },
      dialogVisible: false,
      detailList: [],
      typeIdVal: "",
      title: "",
      isshowAdd: false,
      contractForm: {
        tableName: "",
        contractpath: "",
        id: "",
      },
      isshowEdit: false,
      isShowContract: false,
      contractObj: {
        organizeName: "",
        licenseNumber: "",
        name: "",
        idCard: "",
        address: "",
        mobile: "",
        type: "1",
        id: "",
      },
      isLoading: false,
      fileList: [],
      delVisible: false,
    };
  },
  mounted() {
    const info = localStorage.getItem('bzInfo')
    if (info) {
      const data = JSON.parse(info)
      // this.initDetail()
      if (this.$route.query.active) this.setDialogShow(data, this.$route.query.active)
      else this.setDialogShow(data)
    } else {
      this.$router.push('/system/BaoZ')
      return
    }
  },
  methods: {
    delAuditInfo(row) {
      this.$confirm("此操作将删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        const res = await deleteAuditInfo({id: row.id})
        if (res.code == 10000) {
          this.$message.success('删除成功')
          this.getAuditList(this.auditObj)
        }
      })      
    },
    showFile(row) {
      window.open('https://admin.jstyx.com.cn/web/sh/'+row.file_path)
      return
      readFile({filePath: row.file_path}).then(res => {
        console.log(res)
        const fileName = row.file_path.substring(row.file_path.lastIndexOf('/')+1)
        const fileType = fileName.substring(fileName.lastIndexOf('.')+1)
        downloadFile(res, fileName, fileType)
      })
    },
    showModalInfo(obj) {
      this.auditForm[this.typeIdVal] = obj.id;
      this.isshowAdd = true;
    },
    showContractInfo(obj) {
      this.contractObj.address = obj.address;
      this.contractObj.mobile = obj.phone;
      this.contractObj.name = obj.linkMan;
      this.contractObj.id = obj.id;
      this.contractObj.idCard = obj.idCard;
      this.contractObj.organizeName = obj.organizeName;
      this.contractObj.licenseNumber = obj.licenseNumber;
      this.contractObj.type = obj.organizeName ? "2" : "1";
      this.isShowContract = true;
    },
    showContract(obj) {
      this.contractForm.id = obj.id;
      this.isshowEdit = true;
    },
    setDialogShow(data, active) {
      const type = this.$route.query.type
      const titleList = {
        bz_id: "报装详情",
        bx_id: "报修详情",
        gxh_id: "过销户详情",
      };
      const table_names = {
        bz_id: "tb_bz",
        bx_id: "tb_bx",
        gxh_id: "platformuser",
      };
      this.typeIdVal = type;
      this.title = titleList[type];
      this.contractForm.tableName = table_names[type];
      if (active != null) {
        this.title = active == 1 ? "过户详情" : "销户详情";
      }
      this.auditObj = data;
      this.getAuditList(data);
    },
    async getAuditList(obj) {
      let params = {};
      params[this.typeIdVal] = obj.id;
      const { code, results } = await getAuditInfoById(params);
      if (code === 10000) {
        this.detailList = results;
        this.auditObj = obj;
        this.dialogVisible = true;
      }
    },
    handleRemove(file, fileList) {
      this.fileList = fileList
    },
    handleChange(file, fileList) {
      this.fileList = fileList
    },

    checkUploadFile(file) {
      const fileSize = file.size
      if (fileSize > 1024 * 1024 * 50) {
        this.$message.warning('文件大小不能超过50M')
        return false
      }
    },
    saveAudit() {
      const uploadFiles = this.$refs.upload.uploadFiles
      console.log(uploadFiles,'--d--d-d')
      if (uploadFiles.length > 0) {
        this.$refs.upload.submit();
      } else {
        this.saveAuditInfo()
      }
    },
    async saveAuditInfo() {
      this.$refs["auditFormRef"].validate(async (valid) => {
        if (valid) {
          this.auditForm.audit_user =
            localStorage.getItem("realname") || localStorage.getItem("username");
          this.auditForm[this.typeIdVal] = this.auditObj.id;
          const formData = new FormData();
          formData.append("audit_status", this.auditForm.audit_status);
          formData.append("audit_content", this.auditForm.audit_content);
          formData.append("audit_user", this.auditForm.audit_user);
          formData.append("updatetime", this.auditForm.updatetime);
          formData.append("file_time", this.auditForm.file_time);
          formData.append(this.typeIdVal, this.auditObj.id);
          const { code, msg } = await addAuditInfoById(formData);
          if (code == 10000) {
            this.$message.success(msg);
            this.getAuditList(this.auditObj);
            this.isshowAdd = false;
          } else {
            this.$message.warning(msg);
          }
        }
      });
    },
    async uploadFileAndInfo(file) {
      this.auditForm.audit_user =
        localStorage.getItem("realname") || localStorage.getItem("username");
      this.auditForm[this.typeIdVal] = this.auditObj.id;
      const formData = new FormData();
      formData.append("audit_status", this.auditForm.audit_status);
      formData.append("audit_content", this.auditForm.audit_content);
      formData.append("audit_user", this.auditForm.audit_user);
      formData.append("updatetime", this.auditForm.updatetime);
      formData.append("file_time", this.auditForm.file_time);
      formData.append(this.typeIdVal, this.auditObj.id);
      formData.append("file_type", file.file.name.split('.')[1]);
      formData.append("file_size", file.file.size);
      formData.append('file', file.file)
      const { code, msg, id } = await addAuditInfoById(formData);
      if (code == 10000) {
        this.$message.success(msg);
        this.getAuditList(this.auditObj);
        this.isshowAdd = false;
      } else {
        this.$message.warning(msg);
      }
    },
    async save() {
      const { code, msg } = await addContractPathById(this.contractForm);
      if (code == 10000) {
        this.$message.success(msg);
        this.auditObj.contractpath = this.contractForm.contractpath;
        this.isshowEdit = false;
      } else {
        this.$message.warning(msg);
      }
    },
    
    async saveContract() {
      const formParams = JSON.parse(JSON.stringify(this.contractObj));
      if (formParams.type == 1) {
        delete formParams.organizeName;
        delete formParams.licenseNumber;
      }
      if (Object.values(formParams).every((value) => !!value)) {
        this.isLoading = true;
        const formData = new FormData();
        Object.keys(formParams).map((key) => {
          formData.append(key, formParams[key]);
        });
        const { result, resultmsg } = await contractCreate(formData);
        if (result == "01") {
          this.$message.success("合同已发送,请通知用户签名!");
          this.isShowContract = false;
        } else {
          this.$message.warning(resultmsg);
        }
        this.isLoading = false;
      } else {
        this.$message.warning("请完善信息");
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
