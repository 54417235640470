import Vue from "vue"
import Router from "vue-router"
import { Message } from "element-ui"
Vue.use(Router)
// 页面顶部进度条
import NProgress from "nprogress"
import "nprogress/nprogress.css"
const routes = [
  {
    path: "NewsList",
    meta: { title: "文章列表", icon: 'el-icon-s-grid', platform: '1,2', isShow: true },
    component: () => import("@/views/NewsList.vue"),
  },
  {
    path: "MessageList",
    meta: { title: "留言列表", icon: 'el-icon-s-comment', platform: '1', isShow: true },
    component: () => import("@/views/MessageList.vue"),
  },
  {
    path: "myddc",
    meta: { title: "满意度调查", icon: 'el-icon-s-claim', platform: '1', isShow: true },
    component: () => import("@/views/myddc.vue"),
  },
  {
    path: "GhuXhu",
    meta: { title: "过销户列表", icon: 'el-icon-s-release', platform: '1', isShow: true },
    component: () => import("@/views/GhuXhu.vue"),
  },
  {
    path: "BaoZ",
    meta: { title: "报装列表", icon: 'el-icon-s-release', platform: '1', isShow: true },
    component: () => import("@/views/BaoZ.vue"),
  },
  {
    path: "contractDetail",
    name: "contractDetail",
    meta: { title: "合同详情", icon: 'el-icon-s-release', platform: '1', isShow: false },
    component: () => import("@/views/contractDetail.vue"),
  },
  {
    path: "BaoX",
    meta: { title: "报修列表", icon: 'el-icon-s-release', platform: '1', isShow: true },
    component: () => import("@/views/BaoX.vue"),
  },
  {
    path: "YJSList",
    meta: { title: "一件事报装", icon: 'el-icon-s-release', platform: '1', isShow: true },
    component: () => import("@/views/YJSList.vue"),
  },
  {
    path: "UserList",
    meta: { title: "用户列表", icon: 'el-icon-s-custom', platform: '', isShow: true },
    component: () => import("@/views/UserList.vue"),
  },
]
const route = new Router({
  routes: [
    { path: "*", redirect: "/404", hidden: true },
    {
      path: "/",
      redirect: "/Login",
    },
    {
      path: "/test",
      component: () => import("@/views/test.vue"),

    },
    {
      path: "/Login",
      component: () => import("@/views/Login.vue"),
    },
    {
      path: "/system",
      redirect: "/system/NewsList",
      component: () => import("@/Layout/index.vue"),
      children: routes,
    },
  ],
  scrollBehavior: () => ({ y: 0 }),
})

route.beforeEach((to, from, next) => {
  NProgress.start()
  const token = localStorage.getItem("token")
  if (to.fullPath.indexOf("system") > -1) {
    if (token != null && token) {
      next()
    } else {
      Message.error("用户信息失效，请重新登录")
      next("/Login")
    }
  } else {
    next()
  }
})

route.afterEach(() => {
  window.scroll(0, 0) // 路由切换，滚动条回顶部
  NProgress.done()
})
export default route
export { routes }