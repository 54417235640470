<template>
  <div>
    
    <audit-detail></audit-detail>
    <!-- <div v-if="baoZInfo">
      <div>用户姓名:{{baoZInfo.linkMan}}</div>
      <div>联系方式:{{baoZInfo.linkMan}}</div>
      <div>报装地址:{{baoZInfo.address}}</div>
      <div>报装描述:{{baoZInfo.remark}}</div>
      <div>合同地址:{{baoZInfo.contractpath}}</div>
    </div>
    <el-table :data="auditList">
      <el-table-column label="审核时间" prop="updatetime"></el-table-column>
      <el-table-column label="审核人" prop="audit_user"></el-table-column>
      <el-table-column label="审核状态" prop="audit_status"></el-table-column>
      <el-table-column label="审核备注" prop="audit_content"></el-table-column>
    </el-table> -->
  </div>
</template>

<script>
import {getAuditInfoById} from '@/api/audit'
import AuditDetail from '../components/AuditDetail.vue'
  export default {
  components: { AuditDetail },
    data() {
      return {
        baoZInfo: null,
        auditList: []
      }
    },
    mounted() {
      // const info = localStorage.getItem('bzInfo')
      // if (info) {
      //   this.baoZInfo = JSON.parse(info)
      //   this.initDetail()
      // } else {
      //   this.$router.push('/system/BaoZ')
      //   return
      // }
    },
    methods: {
      async initDetail() {
        const id = this.$route.query.id
        if (id) {
          const res = await getAuditInfoById({id})
          const { code, results } = await getAuditInfoById({id});
          if (code === 10000) {
            this.detailList = results;
            this.auditObj = obj;
            this.dialogVisible = true;
          }
        }
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>