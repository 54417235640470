export const newsTypes = [
  { key: 1, name: "公告" },
  { key: 2, name: "通知" },
  { key: 3, name: "价格公示" },
  { key: 4, name: "停供气通知" },
  { key: 5, name: "企业文件" },
  { key: 6, name: "气质报告" },
  { key: 7, name: "安全常识" },
  { key: 8, name: "便民服务" },
  { key: 9, name: "安全检查通知" },
  { key: 10, name: "抄表通知" },
  { key: 11, name: "服务指南" },
  { key: 12, name: "政府文件公示" },
];

export const contentTypes = [
  { key: 0, name: "报修" },
  { key: 1, name: "咨询" },
  { key: 2, name: "建议" },
  { key: 3, name: "投诉" },
  { key: 4, name: "表扬" },
  { key: 5, name: "报装满意度调查" },
  { key: 6, name: "报修满意度" },
  { key: 7, name: "查下缴费满意度" },
  { key: 8, name: "过户满意度" },
  { key: 9, name: "销户满意度" },
  { key: 10, name: "电子发票满意度" },
  { key: 11, name: "电子发票" },
];
