<template>
  <div>
    <table-template
      :table_data="table_data"
      :isloading="isloading"
      :formatList="['isdelete', 'contractpath', 'isSurvey']"
    >
      <template slot="contractpath" slot-scope="scope">
        <el-link
          type="primary"
          v-if="scope.scope.row.contractpath"
          :underline="false"
          tag="a"
          target="_blank"
          :href="scope.scope.row.contractpath"
          >合同查看</el-link
        >
        <span v-else>--</span>
      </template>
      <template slot="isdelete" slot-scope="scope">
        {{
          scope.scope.row.isdelete == 2 || scope.scope.row.isdelete == -1
            ? "已处理"
            : "未处理"
        }}
      </template>
      <template slot="isSurvey" slot-scope="scope">
        <el-switch
          v-model="scope.scope.row.isSurvey"
          @change="val=>handleChangeSurvey(val,scope.scope.row.id)"
          active-text="开启"
          inactive-text="关闭"
        ></el-switch>
      </template>
      <template slot-scope="scope">
        <el-button
          type="primary"
          size="small"
          @click="showDetail(scope.scope.row)"
          >详情</el-button
        >
        <el-popconfirm
          title="确认删除该条数据？"
          @confirm="delData(scope.scope.row.id)"
        >
          <el-button
            type="danger"
            slot="reference"
            size="small"
            style="margin-left: 10px"
            >删除</el-button
          >
        </el-popconfirm>
      </template>
    </table-template>
    <div style="text-align: right">
      <!-- <base-pageination :pageinfo="table_data.pageinfo" @handlePageClick="handlePageClick"></base-pageination> -->
    </div>
    <!-- <audit-detail ref="auditDetail"></audit-detail> -->
  </div>
</template>

<script>
import BasePageination from "../components/BasePageination.vue";
import ListConditionForm from "../components/ListConditionForm.vue";
import TableTemplate from "../components/TableTemplate.vue";
import { getBZList, delInfo, updateIsSurvey } from "@/api/audit.js";
// import AuditDetail from "../components/AuditDetail.vue";
export default {
  components: {
    ListConditionForm,
    TableTemplate,
    BasePageination,
    // AuditDetail,
  },
  data() {
    return {
      table_data: {
        tableConfig: [
          { key: "linkMan", name: "用户姓名" },
          { key: "phone", name: "联系方式" },
          { key: "address", name: "报装地址" },
          { key: "remark", name: "报装描述" },
          // {key: 'cityNo', name: '燃气户号'},
          { key: "contractpath", name: "合同地址" },
          { key: "createtime", name: "提交时间" },
          { key: "isSurvey", name: "是否开启满意度调查" },
        ],
        data: [],
        pageinfo: { currentpage: 1, pageSize: 10, total: 0 },
      },
      isloading: false,
      bzDetail: null,
      detailList: [],
      dialogVisible: false,
    };
  },
  mounted() {
    this.handlePageClick(1);
  },
  methods: {
    async handleChangeSurvey(val,id){
      await updateIsSurvey({
        id,
        isSurvey:val?1:0
      })
    },
    handleClick() {
      if (this.activeName == 1) {
        if (this.table_data.tableConfig.length == 7) {
          this.table_data.tableConfig.push({
            key: "newlinkman",
            name: "新用户姓名",
          });
          this.table_data.tableConfig.push({
            key: "newphone",
            name: "联系方式",
          });
        }
      } else {
        this.table_data.tableConfig = this.table_data.tableConfig.slice(0, 7);
      }
      this.handlePageClick(1);
    },
    fetchData() {
      this.isloading = true;
      getBZList({
        fromtype: "sys",
      }).then((res) => {
        this.isloading = false;
        const { code, results } = res;
        if (code === 10000) {
          results.forEach(item=>{
            item.isSurvey = item.isSurvey==1?true:false
          });
          this.table_data.data = results
          // this.table_data.pageinfo = page
          // this.dialogVisible = true
        }
      });
    },
    handlePageClick(page) {
      this.table_data.pageinfo.currentpage = page;
      this.fetchData();
    },
    showDetail(obj) {
      // this.$refs.auditDetail.setDialogShow(obj, "bz_id");
      localStorage.setItem('bzInfo', JSON.stringify(obj))
      this.$router.push({
        path: '/system/contractDetail',
        query: { id: obj.id, type: 'bz_id' }
      })
    },
    async delData(id) {
      const { code } = await delInfo({ id, type: 1 });
      if (code === 10000) this.fetchData();
      else this.$message.warning("操作失败！");
    },
  },
};
</script>

<style>
.el-dialog__body {
  padding: 10px;
}
</style>
